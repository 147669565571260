import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Row,
  Stack,
} from "react-bootstrap";

import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import { NavLink } from "react-router-dom";
import React, { useEffect, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import blueSolidLeftIcon from "../../assets/blue_solid_left.png";
import PlantTable from "../user/PlantTable";
import InfoIcon from "@mui/icons-material/Info";
import { MessageContext } from "../../App";
import { FaTimes } from "react-icons/fa";

function Styles() {
  return (
    <style>
      {`
               .row-card{
                 background-color:#f2f5f7;
                 text-align: left;
                 align-items: center;
                 justify-content-center;
                 box-shadow: 0px 0px 3px 1px #d7dadb;
                 border-radius: 5px;
               }

               .form-button{
                   background-color: #167db0;
                   color: white;
                   border-radius: 7px;
                   padding: 3px 20px 3px 20px
                }
               .verify-message{
                    color: #3767A2;
                    font-weight:700px;
                    font-size:16px;
                    padding-left:13px;  
                    letter-spacing: 1px;
                }

                .fw-bold{
                    font-weight:700px;
                    font-size:22px;
                    letter-spacing: 2px;
                    align-items: center;
                }

                .form-button:hover{
                background-color: white !important;
                color : black !important;
                border-color:#167db0 !important;
                }

                .form-button-sp{
                    padding: 1px 10px 1px 10px !important;
                    border-radius: 20px !important;
                }

              .card-height{
               height: 88% !important
              }           
                .user-add-form{
                    font-family: 'Open Sans';
                    font-weight: normal;
                } 
                .user-add-form .row{
                align-items: center;
                }
                .user-add-form .form-label{
                 font-weight:bold;
                 margin: 0px;
                }
                 .user-add-form .form-select{
                 font-weight:normal !important;
                 font-family: 'Open Sans';
                }

                .user-add-form .form-button{
                  font-family: 'Open Sans';
                    font-weight: bold;
                }
               .user-add-form .form-control{
                  font-family: 'Open Sans';
                    font-weight: normal;
                }
               .user-add-form .form-check-input{
                   box-shadow: 0px 1px 3px #00000029;
                   border: 1px solid #0F0D0D;
                   border-radius: 5px;
                   opacity: 1;
                   width: 20px;
                   height: 20px;

               }
              .entity-form-left-arrow{
                width: 23px;
                height: 23px;
                opacity: 1;
                margin-right: 5px;
               }
                .user-title{
                font-family: 'Open Sans';
                font-weight: bold;
                font-size: 20px;
                color: #0F0D0D;
                }
        `}
    </style>
  );
}

// const { t } = useTranslation(['menu','user']);

function UserAddPage({ stateChanger }) {
  const [latlngs, setLatLngs] = useState({
    lat: 12.936847666033527,
    lng: 77.57351512286992,
  });
  const [userRoles, setUserRoles] = useState([]);
  const onMessageChange = useContext(MessageContext);

  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [timer, setTimer] = useState(20);
  const [showResend, setShowResend] = useState(false);
  const [isOtpValid, setIsOtpValid] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  const otpRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [selectPlants, setSelectPlants] = React.useState([]);
  const [selectedPlantIds, setSelectedPlantIds] = useState([]); // Track selected plants

  const handlePlantSelection = (plantIds) => {
    console.log("Selected Plant IDs in UserAddPage:", plantIds);
    setFormData((prevData) => ({
      ...prevData,
      plantIds: plantIds, // Set plantIds in formData directly
    }));
  };

  const { t } = useTranslation("user");

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const entityId = userData?.entityId || ""; // Extract entityId from session storage

  useEffect(() => {}, [latlngs]);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    mobileNo: "",
    role: "",
    entityId: entityId,
    plantIds: [],
  });

  // function handlePlantSelection(p) {
  //   setPlantId(p);
  // }

  // const [selectedPlantIds, setSelectedPlantIds] = useState([]);

  // const handleSelectedPlants = (plantIds) => {
  //   setSelectedPlantIds(plantIds);
  //   console.log("Selected Plant IDs in Add Page:", plantIds);
  // };

  useEffect(() => {
    // Fetch roles from backend
    fetch("https://solar-api.antsai.in/api/user/getUserRoles")
      .then((response) => response.json())
      .then((data) => {
        setUserRoles(data);
      })
      .catch((error) => console.error("Error fetching user roles:", error));
  }, []);

  const handleVerifyClick = async () => {
    if (!formData.emailId) {
      onMessageChange({
        type: "Warning",
        info: "Please enter your email first.",
      });
      return;
    }

    try {
      // Step 1: Check if the email already exists
      const checkEmailResponse = await fetch(
        "https://solar-api.antsai.in/checkemail",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email: formData.emailId }),
        }
      );

      const checkEmailData = await checkEmailResponse.json();

      if (checkEmailData.exists) {
        // If email exists, show message and stop further actions
        onMessageChange({
          type: "Danger",
          info: "User already exists.",
        });
        return;
      }

      // Step 2: If email does not exist, send OTP
      const otpResponse = await fetch("https://solar-api.antsai.in/send-otp", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user_email: formData.emailId }),
      });

      if (otpResponse.ok) {
        setIsOtpModalOpen(true);
        setTimer(20);
        setShowResend(false);
        setOtp(["", "", "", ""]);
        onMessageChange({
          type: "Success",
          info: "OTP sent to your email!",
        });
      } else {
        onMessageChange({
          type: "Danger",
          info: "Failed to send OTP. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error in checking email or sending OTP:", error);
      onMessageChange({
        type: "Danger",
        info: "An error occurred. Please try again.",
      });
    }
  };

  // Resend OTP
  const handleResendOtp = async () => {
    setTimer(20);
    setShowResend(false);
    setOtp(["", "", "", ""]);
    otpRefs[0].current.focus();
    try {
      const response = await fetch("https://solar-api.antsai.in/send-otp", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user_email: formData.emailId }),
      });
      if (response.ok) {
        onMessageChange({
          type: "Success",
          info: "OTP resent successfully!",
        });
      } else {
        onMessageChange({
          type: "Danger",
          info: "Failed to resend OTP. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  };

  // Verify OTP
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    const otpValue = otp.join("");
    if (otpValue.length === 4) {
      try {
        const response = await fetch("https://solar-api.antsai.in/verify-otp", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ user_email: formData.emailId, otp: otpValue }),
        });
        if (response.ok) {
          setIsOtpValid(true);
          setIsEmailVerified(true);
          onMessageChange({
            type: "Success",
            info: "OTP Verified!",
          });
          setIsOtpModalOpen(false);
        } else {
          setIsOtpValid(false);
          onMessageChange({
            type: "Danger",
            info: "Invalid OTP. Please try again.",
          });
        }
      } catch (error) {
        console.error("Error verifying OTP:", error);
      }
    } else {
      setIsOtpValid(false);
      onMessageChange({
        type: "Warning",
        info: "Please enter a valid 4-digit OTP.",
      });
    }
  };

  // OTP timer
  useEffect(() => {
    if (isOtpModalOpen && timer > 0) {
      const countdown = setInterval(
        () => setTimer((prevTime) => prevTime - 1),
        1000
      );
      return () => clearInterval(countdown);
    } else if (timer === 0) {
      setShowResend(true);
    }
  }, [timer, isOtpModalOpen]);

  // OTP input handler
  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/^\d$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value !== "" && index < 3) {
        otpRefs[index + 1].current.focus();
      }
    }
  };

  // Close OTP modal
  const handleCloseOtpModal = () => setIsOtpModalOpen(false);

  const navToAdmin = () => {
    navigate("/admin");
  };

  // function handleChange(event) {
  //   const target = event.currentTarget;
  //   const { name, value } = event.target;
  //   setFormData((prevData) => ({ ...prevData, [name]: value }));
  //   // const value =
  //   //   target.type === "checkbox"
  //   //     ? target.checked
  //   //     : target.type === "file"
  //   //     ? target.files
  //   //     : target.value;
  //   // const name = target.name;
  //   // if(target.type === 'number' && (target.name==='latitude' || target.name==='longtitude')){
  //   //     value= target.value;
  //   //     name='latlng';
  //   // }

  //   setFormData({ ...formData, [name]: value });
  // }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value, // This ensures the name attribute on the input field matches the key in formData
    }));
  };

  // const handleSubmit = (event) => {
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   }
  //   setValidated(true);
  // };

  const fileChanged = (file) => {
    console.log(file);
  };

  const updateLatLng = (latlng) => {
    setFormData({ ...formData, latlng: latlng });
    setLatLngs({ ...latlng });
  };

  const onVerify = () => {
    alert("Verify invoked");
  };
  const onCancel = () => {};
  const onDialApi = () => {};

  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData((prevData) => ({ ...prevData, [name]: value }));
  // };
  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const entityId =
      JSON.parse(sessionStorage.getItem("userData"))?.entityId || "";

    console.log("Entity ID from session storage:", entityId); // Check if it's populated
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log("Form Data:", formData);
    console.log("Selected Plant IDs:", selectedPlantIds);
    console.log("isEmailVerified:", isEmailVerified);

    // Ensure email is verified and required fields are filled
    if (!isEmailVerified) {
      onMessageChange({
        type: "Warning",
        info: "Please verify your email before submission.",
      });
      return;
    }

    const { firstName, lastName, emailId, mobileNo, role, entityId } = formData;
    const plantIds = formData.plantIds; // Ensure plantIds is taken from formData

    // // Confirmation dialog if no plants are selected
    // if (selectedPlantIds.length === 0) {
    //   const confirmWithoutPlants = window.confirm(
    //     "You haven't selected any plants. Do you want to add the user without any plants?"
    //   );
    //   if (!confirmWithoutPlants) {
    //     return; // Stop form submission if the user cancels
    //   }
    // }

    const payload = {
      firstName,
      lastName,
      emailId,
      mobileNo,
      role,
      entityId,
      plantIds, // Directly use formData.plantIds
    };

    console.log("payloadData", payload);

    try {
      const response = await fetch(
        "https://solar-api.antsai.in/api/user/add_user1",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        onMessageChange({ type: "Success", info: "User added successfully!" });
        setFormData({
          firstName: "",
          lastName: "",
          emailId: "",
          mobileNo: "",
          role: "",
          entityId: entityId,
          plantIds: [],
        });
        setSelectedPlantIds([]); // Reset selected plants
      } else {
        const errorData = await response.json();
        onMessageChange({
          type: "Danger",
          info: `Failed to add user: ${errorData.message || "Unknown error"}`,
        });
      }
    } catch (error) {
      onMessageChange({
        type: "Danger",
        info: "An error occurred while adding the user. Please try again.",
      });
    }
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center user-add-form">
      <Container className="mw-100">
        <Styles />
        <Row className="mt-2 mb-2">
          <Col className="d-flex flex-row align-items-center justify-content-start center-block">
            {/* <img src={LeftArrowIcon} alt="Logo" /> */}
            {/* <SvgIcon sx={{color:green[500]}} component={LeftArrowIcon} inheritViewBox/> */}
            <div className="text-decoration-none d-flex align-items-center">
              <img
                onClick={() => stateChanger(7)}
                className="entity-form-left-arrow"
                src={blueSolidLeftIcon}
                alt="sort"
                style={{ cursor: "pointer" }}
              />

              <span className="fw-bold text-decoration-none text-black">
                {t("user:user")}
              </span>
            </div>
          </Col>
          <Col className="d-flex flex-row align-items-center justify-content-end"></Col>
        </Row>
      </Container>
      <Container className="mw-100">
        <Form
          className="w-100 p-0 m-0 user-add-form"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <div className=" d-flex flex-column align-items-center justify-content-center w-80 p-0 mt-2">
            <div className="w-100 text-left">
              <span className="user-title">{t("user:addNewUser")}</span>
            </div>
            <Row className="w-100 row-card p-4 m-2 d-flex flex-lg-row  flex-column">
              <Col>
                <Stack as="row" gap={2} direction="vertical">
                  <Col>
                    <Form.Group controlId="userFirstname">
                      <Row className="w-100">
                        <Col md={3}>
                          <Form.Label className="text-nowrap">
                            {t("user:firstname")}{" "}
                            <StarOutlinedIcon
                              style={{ color: "red", fontSize: "smaller" }}
                            />{" "}
                            <spa>:</spa>
                          </Form.Label>
                        </Col>
                        <Col md={9}>
                          {/* <Form.Control
                            name="userFirstname"
                            defaultValue={formData.userFirstname}
                            required
                            type="string"
                            onChange={(e) => handleChange(e)}
                            placeholder="Enter First Name"
                          /> */}
                          <Form.Control
                            name="firstName" // Change 'firstname' to 'firstName'
                            value={formData.firstName || ""}
                            onChange={(e) => handleChange(e)}
                            required
                            placeholder="Enter First Name"
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                  <Col span>
                    <Form.Group controlId="userLastname">
                      <Row className="w-100">
                        <Col md={3}>
                          <Form.Label className="text-nowrap">
                            {t("user:lastname")} <spa>:</spa>
                          </Form.Label>
                        </Col>
                        <Col md={9}>
                          {/* <Form.Control
                            name="userLastname"
                            defaultValue={formData.userLastname}
                            required
                            type="string"
                            onChange={(e) => handleChange(e)}
                            placeholder="Enter Last Name"
                          /> */}
                          <Form.Control
                            name="lastName"
                            value={formData.lastName || ""}
                            onChange={(e) => handleChange(e)}
                            required
                            placeholder="Enter Last Name"
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="userEmailid">
                      <Row className="w-100">
                        <Col md={3}>
                          <Form.Label className="text-nowrap">
                            {t("user:emailId")} <spa>:</spa>
                          </Form.Label>
                        </Col>
                        <Col md={7}>
                          {/* <Form.Control
                            name="email"
                            defaultValue={formData.email}
                            required
                            type="string"
                            onChange={(e) => handleChange(e)}
                            placeholder="Enter e-mail id"
                          /> */}
                          <Form.Control
                            name="emailId"
                            value={formData.emailId || ""}
                            onChange={(e) => handleChange(e)}
                            required
                            placeholder="Enter Email"
                          />
                        </Col>
                        <Col md={2}>
                          <Button
                            onClick={() => handleVerifyClick()}
                            disabled={isEmailVerified}
                            className="form-button rounded p-1 form-button-sp m-2"
                          >
                            {isEmailVerified ? "Verified" : "Verify"}
                          </Button>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                </Stack>
              </Col>
              <Col>
                <Stack gap={3} direction="vertical">
                  <Form.Group controlId="userMobile">
                    <Row className="w-100">
                      <Col md={3}>
                        <Form.Label className="text-nowrap">
                          {t("user:mobileNo")}{" "}
                          <StarOutlinedIcon
                            style={{ color: "red", fontSize: "smaller" }}
                          />{" "}
                          :
                        </Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          name="mobileNo"
                          value={formData.mobileNo || ""}
                          required
                          type="tel"
                          maxLength="15"
                          pattern="^\+?[1-9]\d{1,14}$"
                          onChange={handleChange}
                          placeholder="Mobile Number"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid mobile number.
                        </Form.Control.Feedback>
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group controlId="userRole">
                    <Row className="w-100">
                      <Col md={3}>
                        <Form.Label className="text-nowrap">
                          {t("role")}{" "}
                          <StarOutlinedIcon
                            style={{ color: "red", fontSize: "smaller" }}
                          />{" "}
                          <spa>:</spa>
                        </Form.Label>
                      </Col>
                      <Col md={8}>
                        {/* <Form.Select
                          name="userRole"
                          defaultValue={formData.userRole}
                          onChange={(e) => handleChange(e)}
                          required
                          placeholder="User Role"
                          size="md"
                        >
                          <option value="">{t("selectRole")}</option>
                          {userRoles.map((v) => {
                            return <option value={v}>{v.toString()}</option>;
                          })}
                        </Form.Select> */}
                        <Form.Select
                          name="role"
                          value={formData.role || ""}
                          onChange={(e) => handleChange(e)}
                          required
                          placeholder="User Role"
                          size="md"
                        >
                          <option value="">{t("selectRole")}</option>
                          {userRoles.map((role) => (
                            <option key={role.role_id} value={role.role_name}>
                              {role.role_name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col md={1}>
                        {/* <InfoIcon sx={{ color: "#167DB0", fontSize: "30px" }} />{" "} */}
                      </Col>
                    </Row>
                  </Form.Group>
                  <div className="p-4"></div>
                </Stack>
              </Col>
            </Row>
            <Row className="w-100">
              <PlantTable onSelectPlant={handlePlantSelection} />
            </Row>
            <Row className="w-100">
              <div className="w-100 d-flex flex-row align-items-center justify-content-end">
                <Button type="submit" className="ml-3 m-1 form-button">
                  Save User
                </Button>
              </div>
            </Row>
          </div>
          {/* OTP Modal */}
          {isOtpModalOpen && (
            <div>
              <div
                className="otp-backdrop"
                onClick={() => handleCloseOtpModal()}
              ></div>
              <div className="otp-modal">
                <div className="otp-modal-content">
                  <span
                    className="otp-close-icon"
                    onClick={() => handleCloseOtpModal()}
                  >
                    <FaTimes />
                  </span>
                  <h3>Enter OTP</h3>
                  <div className="otp-inputs">
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        type="text"
                        maxLength="1"
                        value={digit}
                        onChange={(e) => handleOtpChange(e, index)}
                        ref={otpRefs[index]}
                        className="otp-input"
                      />
                    ))}
                  </div>
                  <div className="d-flex flex-row gap-2">
                    <div>
                      {timer > 0 ? (
                        <span className="otp-timer">Time left: {timer}s</span>
                      ) : (
                        <Button
                          className="form-button"
                          onClick={() => handleResendOtp()}
                        >
                          Resend OTP
                        </Button>
                      )}
                    </div>
                    {!isOtpValid && (
                      <p className="error-message">
                        Please enter a valid 4-digit OTP.
                      </p>
                    )}
                    <Button
                      className="form-button"
                      onClick={(e) => handleOtpSubmit(e)}
                    >
                      Submit OTP
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Form>
      </Container>
    </div>
  );
}

export default UserAddPage;
