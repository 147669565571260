import React  from "react";
import SolarNavbar from "../navbar/SolarNavbar";
import "./Plants.css";
import PeakPower from "./PeakPower";
import Alerts from "./Alerts";
import Production from "./Production";
import uploadIcon from "./../../assets/upload-icon.svg"; 
import { Container, Row, Col } from "react-bootstrap";
import PlantsTable from "./PlantsTable";
import BottomHeaderBar from "./../navbar/BottomHeaderBar";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Plants = () => {
    const navigate=useNavigate();
    const plantCount = 20;

     function addNewPlant() {
        navigate("/dashboard/plants/addplant");
    }
   
    const { t } = useTranslation('plant');

    return (
        <div className="d-flex flex-column align-items-center justify-content-center">
            <SolarNavbar />
            <BottomHeaderBar />
            <Container as={"div"} fluid className="mw-100">
                <Row className="my-2 py-2">
                    <Col >
                        <div >
                            <h1 className="header-title">
                            {t('title')} 
                                {/* <span className="plant-count"></span> */}
                            </h1>
                        </div>
                    </Col>
                    <Col >
                        <div className="d-flex  flex-row  align-items-center justify-content-end ">
                            <button className="add-plant-button " onClick={()=>addNewPlant()}>Add New Plant</button>
                            {/* <div className="divider"></div>
                            <img src={uploadIcon} alt="Upload" className="upload-icon" /> */}
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className="mw-100 px-10">
                <Row>
                <Col className="m-lg-0 m-2"><PeakPower /></Col>    
                <Col className="m-lg-0 m-2"><Alerts /></Col>
                <Col className="m-lg-0 m-2"><Production /></Col>
                </Row>
            </Container>
            <Container className="mw-100">
                <PlantsTable/>
            </Container>

        </div> 
    );
};

export default Plants;
