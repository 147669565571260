import React  from "react";
import SolarNavbar from "../../dashboard/navbar/SolarNavbar";
import "./User.css";
import { Col, Container,  Nav, NavItem, NavLink, Row, Stack, Tab } from "react-bootstrap";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Divider,} from "@mui/material";
import UserTable from "./UserTable";
import AdminBottomHeaderBar from "../../dashboard/navbar/AdminBottomHeaderBar";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const User = () => {
    const navigate=useNavigate();
    const userCount = 20;

     function addNewUser() {
        navigate("/admin/user/add");
    }
   
    const { t } = useTranslation(['user','menu'])

    return (
        <div className="d-flex flex-column align-items-center justify-content-center">
            <SolarNavbar />
            <AdminBottomHeaderBar />
           
            <Container className="mw-100">
                <Row>
                    <Tab.Container id="left-tabs-example p-2 m-2" defaultActiveKey="first">
                        <Container className="mw-100 "> <Stack gap={0} direction="vertical">
                            <Nav as={"div"} variant="underline" className="flex-row tab-nav">
                                <NavItem >
                                    <NavLink eventKey="first"> {t('menu:entity')} </NavLink>
                                </NavItem>
                                <NavItem >
                                    <NavLink eventKey="second"> {t('menu:plant')} </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink eventKey="third" >{t('menu:role')}</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink eventKey="fourth" >{t('menu:user')}</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink eventKey="fifth" >{t('menu:report.template')}</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink eventKey="sixth" >{t('menu:catalogue')}</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink eventKey="seventh" >{t('menu:setting')}</NavLink>
                                </NavItem>
                            </Nav>
                            <Divider className="bg-black" />
                            <Tab.Content className="mt-2 pt-2">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Tab.Pane eventKey="first">  
                                    <Container as={"div"} fluid className="mw-100">
                                        <Row className="m-2 p-2">
                                            <Col className="px-0">
                                                <div >
                                                    <h1 className="header-title">
                                                    {t('user.list')} 
                                                        {/* <span className="user-count"></span> */}
                                                    </h1>
                                                </div>
                                            </Col>
                                            <Col className="px-0">
                                                <div className="d-flex  flex-row  align-items-center justify-content-end ">
                                                    <button className="add-user-button " onClick={()=>addNewUser()}> {t('user.addnew')} </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>    
                                    <UserTable/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second"> <UserTable/></Tab.Pane>
                                <Tab.Pane eventKey="third"> <UserTable/></Tab.Pane>
                                <Tab.Pane eventKey="fourth">  
                                    <Container as={"div"} fluid className="mw-100">
                                        <Row className="m-2 p-2">
                                            <Col className="px-0">
                                                <div >
                                                    <h1 className="header-title">
                                                    {t('user.list')} 
                                                        {/* <span className="user-count"></span> */}
                                                    </h1>
                                                </div>
                                            </Col>
                                            <Col className="px-0">
                                                <div className="d-flex  flex-row  align-items-center justify-content-end ">
                                                    <button className="add-user-button " onClick={()=>addNewUser()}> {t('user.addnew')} </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>    
                                    <UserTable/>
                                </Tab.Pane>
                                </LocalizationProvider>
                            </Tab.Content>
                        </Stack>
                        </Container>
                    </Tab.Container>
                </Row>
            </Container>
        </div> 
    );
};

export default User;
