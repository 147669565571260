// import React, {useState} from "react";
// import { useNavigate } from "react-router-dom"; // Import useNavigate
// import Select from 'react-select';
// import { FaEye, FaEyeSlash } from 'react-icons/fa'; // For eye icons
// import "./Signup.css";
// import backgroundImage from "../assets/solar-dl-login-bg-2x.png"; // Adjust the path according to your project structure
// import logo from "../assets/logog.svg";
// import details from "../assets/detailsbutton.svg";

// const signupCard = () => {
//   const navigate = useNavigate(); // Initialize the navigate function

//   const handleSignUp = () => {
//     // Perform signup validation here (e.g., check if fields are filled)

//     // Redirect to the login screen on successful signup
//     navigate("#");
//   };

//   const handleLogin = () => {
//     // Perform login validation here (e.g., check if fields are filled)

//     // Redirect to the / route
//     navigate("/");
//   };


//   ///////////// Handling mobile input ////////////////

//   // Country code options
//   // const countryCodeOptions = [
//   //   { value: '+1', label: '🇺🇸 +1 (USA)' },
//   //   { value: '+44', label: '🇬🇧 +44 (UK)' },
//   //   { value: '+91', label: '🇮🇳 +91 (India)' },
//   //   { value: '+61', label: '🇦🇺 +61 (Australia)' },
//   //   { value: '+81', label: '🇯🇵 +81 (Japan)' },
//   //   // Add more country codes as necessary
//   // ];

//   // const [selectedCountryCode, setSelectedCountryCode] = useState(countryCodeOptions[0]); // Default to the first option
//   // const [phoneNumber, setPhoneNumber] = useState('');

//   // // Handle the change in the country code dropdown
//   // const handleCountryCodeChange = (selectedOption) => {
//   //   setSelectedCountryCode(selectedOption);
//   // };

//   // // Handle the phone number input change
//   // const handlePhoneNumberChange = (e) => {
//   //   setPhoneNumber(e.target.value);
//   // };

//   const [password, setPassword] = useState('');
//   const [isPasswordVisible, setIsPasswordVisible] = useState(false); // Password visibility toggle

//   // Handle password visibility toggle
//   const togglePasswordVisibility = () => {
//     setIsPasswordVisible(!isPasswordVisible);
//   };

//   const handlePasswordChange = (e) => {
//     setPassword(e.target.value);
//   };



//   return (
//     <div
//       className="signup-card-container"
//       style={{ backgroundImage: `url(${backgroundImage})` }}
//     >
//       <div className="signup-card">
//         <div className="signup-container">
//           <div className="signup-logo-container">
//             <img 
//               src={logo}
//               className="signup-logo"
//             />

//           </div>
//           <div className="signup-form-container">
//             <div className="signup-title-container">
//               <h2 className="signup-title">
//                 <span>Sign Up</span>
//               </h2>
//             </div>
//             <div className="signup-inputs-container">
//               <div className="signup-input-left">
//                 <div className="signup-input-container">
//                   <label className="signup-input-label" htmlFor="user-id">
//                   First Name <span className="signup-required">*</span>
//                   </label>
//                   <input
//                     type="text"
//                     id="first-name"
//                     placeholder="First Name"
//                     className="signup-input"
//                   />
//                 </div>
//                 <div className="signup-input-container">
//                   <label className="signup-input-label" htmlFor="password">Password <span className="signup-required">*</span></label>
//                   <div style={{ position: 'relative' }}>
//                     <input
//                       type={isPasswordVisible ? 'text' : 'password'}
//                       value={password}
//                       onChange={handlePasswordChange}
//                       placeholder="Enter password"
//                       className="signup-input"
//                     />
//                     <span
//                       onClick={togglePasswordVisibility}
//                       style={{
//                         position: 'absolute',
//                         right: '10px',
//                         top: '42%',
//                         transform: 'translateY(-50%)',
//                         cursor: 'pointer',
//                         color: '#888',
//                       }}
//                     >
//                       {isPasswordVisible ? <FaEye /> : <FaEyeSlash />}
//                     </span>
//                   </div>
//                 </div>
//                 <div className="signup-input-container">
//                   <label className="signup-input-label" htmlFor="password">
//                   Mobile Number <span className="required">*</span>
//                   </label>
//                   <div style={{display:"flex", gap:"10px"}}>
//                     <select style={{width:"30%"}} className="signup-input">
//                     <option value="+91">+91</option>
//                     <option value="+1">+1</option>
//                     <option value="+2">+2</option>
//                     <option value="+3">+3</option>
//                     </select>
//                     <input
//                       type="password"
//                       id="mobile-number"
//                       placeholder="Mobile Number"
//                       className="signup-input"
//                     />  
//                   </div>

//                 </div>

//                 <div className="signup-input-container">
//                   <label className="signup-input-label" htmlFor="user-id">
//                   Pincode <span className="signup-required">*</span>
//                   </label>
//                   <input
//                     type="text"
//                     id="signup-pincode"
//                     placeholder="570001"
//                     className="signup-input"
//                   />
//                 </div>
//               </div>
//               <div className="signup-input-right">
//                 <div className="signup-input-container">
//                   <label className="signup-input-label" htmlFor="user-id">
//                   Last Name <span className="signup-required">*</span>
//                   </label>
//                   <input
//                     type="text"
//                     id="last-name"
//                     placeholder="Last Name"
//                     className="signup-input"
//                   />
//                 </div>
//                 <div className="signup-input-container">
//                   <label className="signup-input-label" htmlFor="password">
//                   Email ID <span className="signup-required">*</span>
//                   </label>
//                   <div style={{display:"flex", gap:"10px"}}>
//                     <input
//                       type="email"
//                       id="email-id"
//                       placeholder="Email ID"
//                       className="signup-input"
//                     />
//                     <button className="signup-verify-button">Verify</button>
//                   </div>  
//                 </div>
//                 <div className="signup-input-container">
//                   <label className="signup-input-label" htmlFor="password">
//                   Country <span className="signup-required">*</span>
//                   </label>
//                     <select className="signup-input">
//                     <option value="IN">India</option>
//                     <option value="US">United States</option>
//                     <option value="CA">Canada</option>
//                     <option value="GE">Germany</option>
//                     </select>
//                 </div>
//                 <div className="signup-input-container">
//                   <label className="signup-input-label" htmlFor="user-id">
//                   Entity Name
//                   </label>
//                   <input
//                     type="text"
//                     id="entity-name"
//                     placeholder="Entity Name"
//                     className="signup-input"
//                   />
//                 </div>

//                 <button className="signup-button" onClick={handleLogin}>
//                   Sign Up
//                 </button>
//               </div>
//             </div>
//             <div className="signup-button-container">
//               <span className="signup-login-already">
//                 Already Have Account
//               </span>
//               <a href="" className="signup-login-back" onClick={handleLogin}>Login</a>
//             </div>            
//           </div>          
//         </div>












//       </div>
//     </div>
//   );
// };

// export default signupCard;
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { FaEye, FaEyeSlash, FaTimes } from 'react-icons/fa';
import "./Signup.css";
import backgroundImage from "../assets/solar-dl-login-bg-2x.png"; // Adjust the path according to your project structure
import logo from "../assets/logog.svg";
import { MessageContext } from '../App';
// import details from "../assets/detailsbutton.svg";

const signupCard = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false); // Password visibility toggle
  const [mobileNumber, setMobileNumber] = useState('');
  const [pinCode, setPinCode] = useState('');
  const [country, setCountry] = useState(''); // Initialize as an empty string to store country_name

  const [entityName, setEntityName] = useState('');
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [otp, setOtp] = useState(['', '', '', '']);
  const [timer, setTimer] = useState(20);
  const [showResend, setShowResend] = useState(false);
  const [isOtpValid, setIsOtpValid] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(false); // Tracks email verification status
  const otpRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [countries, setCountries] = useState([]); // State to store countries
  const [isLoadingCountries, setIsLoadingCountries] = useState(false); // Loading state
  const [countryError, setCountryError] = useState(null); // Error state

  const onMessageChange = useContext(MessageContext);

  // Fetch countries from API using POST method
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://solar-api.antsai.in/api/countries', {
          method: 'POST', // Ensure this is the correct method
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({}) // Adjust payload if required by API
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Fetched countries:', data); // Debugging

          // Check if data is an array
          if (Array.isArray(data)) {
            setCountries(data);
          } else {
            console.error('Unexpected data format:', data);
          }
        } else {
          console.error(`Failed to fetch countries: ${response.status} ${response.statusText}`);
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  const handleSignUp = async () => {
    if (!isEmailVerified) {
      // alert("Please verify your email before signing up.");      
      onMessageChange({ type: 'Danger', 'info': 'Please verify your email before signing up.' })
      return;
    }
    if (!country) {
      // alert("Please select a country.");
      onMessageChange({ type: 'Danger', 'info': 'Please select a country.' })
      return;
    }
    // Set up the request payload
    const payload = {
      firstName,
      lastName,
      email,
      password,
      mobileNumber: `+91 ${mobileNumber}`,
      pinCode,
      country, // This now contains the country_name
      entityName,
      otp_status: true    // assuming OTP is already verified for now
    };

    // Log the payload to verify data being sent
    console.log("Signup payload:", payload);

    try {
      const response = await fetch('https://solar-api.antsai.in/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        // alert('Signup successful!');
        onMessageChange({ type: 'Success', 'info': 'Signup successful!' })
        navigate("/login"); // Redirect to the login page on success
      } else {
        const errorData = await response.json();
        // alert(`Signup failed: ${errorData.message}`);
        onMessageChange({ type: 'Danger', 'info': `Signup failed: ${errorData.message}` })
      }
    } catch (error) {
      console.error("Error during signup:", error);
      // alert('An error occurred. Please try again.');
      onMessageChange({ type: 'Danger', 'info': 'An error occurred. Please try again.' })
    }
  };

  const handleLogin = () => {
    // Perform login validation here (e.g., check if fields are filled)

    // Redirect to the / route
    navigate("/");
  };

  // Handle password visibility toggle
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  // Send OTP
  const handleVerifyClick = async () => {
    if (!email) {
      // alert("Please enter your email first.");
      
      onMessageChange({ type: 'Danger', 'info': 'Please enter your email first.' })
      return;
    }

    try {
      const response = await fetch('https://solar-api.antsai.in/send-otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_email: email }),
      });

      if (response.ok) {
        setIsOtpModalOpen(true);
        setTimer(20);
        setShowResend(false);
        setOtp(['', '', '', '']);
        // alert('OTP sent to your email!');
        onMessageChange({ type: 'Success', 'info': 'OTP sent to your email!' })
      } else {
        // alert('Failed to send OTP. Please try again.');
        onMessageChange({ type: 'Danger', 'info': 'Failed to send OTP. Please try again.' })
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  // Resend OTP
  const handleResendOtp = async () => {
    setTimer(20);
    setShowResend(false);
    setOtp(['', '', '', '']);
    otpRefs[0].current.focus();
    try {
      const response = await fetch('https://solar-api.antsai.in/send-otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_email: email }),
      });

      if (response.ok) {
        // alert('OTP resent successfully!');
        onMessageChange({ type: 'Success', 'info': 'OTP resent successfully!' })
      } else {
        // alert('Failed to resend OTP. Please try again.');
        onMessageChange({ type: 'Danger', 'info': 'Failed to resend OTP. Please try again.' })
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  };


  // Handles OTP input change
  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/^\d$/.test(value) || value === '') {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value !== '' && index < 3) {
        otpRefs[index + 1].current.focus();
      }
    }
  };

  // Verify OTP
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    const otpValue = otp.join('');

    if (otpValue.length === 4) {
      try {
        const response = await fetch('https://solar-api.antsai.in/verify-otp', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_email: email, otp: otpValue }),
        });

        if (response.ok) {
          setIsOtpValid(true);
          setIsEmailVerified(true);  // Email verified successfully
          // alert('OTP Verified!');
          onMessageChange({ type: 'Success', 'info': 'OTP Verified!' })
          setIsOtpModalOpen(false);
        } else {
          setIsOtpValid(false);
          // alert('Invalid OTP. Please try again.');
          onMessageChange({ type: 'Danger', 'info': 'Invalid OTP. Please try again.' })
        }
      } catch (error) {
        console.error("Error verifying OTP:", error);
      }
    } else {
      setIsOtpValid(false);
      // alert('Please enter a valid 4-digit OTP.');
      onMessageChange({ type: 'Danger', 'info': 'Please enter a valid 4-digit OTP.' })
    }
  };

  // Countdown timer effect
  useEffect(() => {
    if (isOtpModalOpen && timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(countdown);
    } else if (timer === 0) {
      setShowResend(true);
    }
  }, [timer, isOtpModalOpen]);



  // Close OTP modal
  const handleCloseOtpModal = () => {
    setIsOtpModalOpen(false);
  };

  const removeToast=(id)=>{

  }

  return (
    <div
      className="signup-card-container"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="signup-card">
        <div className="signup-container">
          <div className="signup-logo-container">
            <img
              src={logo}
              className="signup-logo"
            />

          </div>
          <div className="signup-form-container">
            <div className="signup-title-container">
              <h2 className="signup-title">
                <span>Sign Up</span>
              </h2>
            </div>
            <div className="signup-inputs-container">
              <div className="signup-input-left">
                <div className="signup-input-container">
                  <label className="signup-input-label" htmlFor="user-id">
                    First Name <span className="signup-required">*</span>
                  </label>
                  <input
                    type="text"
                    id="first-name"
                    placeholder="First Name"
                    className="signup-input"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="signup-input-container">
                  <label className="signup-input-label" htmlFor="password">Password <span className="signup-required">*</span></label>
                  <div style={{ position: 'relative' }}>
                    <input
                      type={isPasswordVisible ? 'text' : 'password'}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter password"
                      className="signup-input"
                    />
                    <span
                      onClick={togglePasswordVisibility}
                      style={{
                        position: 'absolute',
                        right: '10px',
                        top: '42%',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                        color: '#888',
                      }}
                    >
                      {isPasswordVisible ? <FaEye /> : <FaEyeSlash />}
                    </span>
                  </div>
                </div>
                <div className="signup-input-container">
                  <label className="signup-input-label" htmlFor="password">
                    Mobile Number <span className="required">*</span>
                  </label>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <select style={{ width: "30%" }} className="signup-input">
                      <option value="+91">+91</option>
                      <option value="+1">+1</option>
                      <option value="+2">+2</option>
                      <option value="+3">+3</option>
                    </select>
                    <input
                      type="text"
                      id="mobile-number"
                      placeholder="Mobile Number"
                      className="signup-input"
                      value={mobileNumber}
                      onChange={(e) => setMobileNumber(e.target.value)}
                    />
                  </div>

                </div>

                <div className="signup-input-container">
                  <label className="signup-input-label" htmlFor="user-id">
                    Pincode <span className="signup-required">*</span>
                  </label>
                  <input
                    type="text"
                    id="signup-pincode"
                    placeholder="570001"
                    className="signup-input"
                    value={pinCode}
                    onChange={(e) => setPinCode(e.target.value)}
                  />
                </div>
              </div>
              <div className="signup-input-right">
                <div className="signup-input-container">
                  <label className="signup-input-label" htmlFor="user-id">
                    Last Name <span className="signup-required">*</span>
                  </label>
                  <input
                    type="text"
                    id="last-name"
                    placeholder="Last Name"
                    className="signup-input"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
                <div className="signup-input-container">
                  <label className="signup-input-label">Email ID <span className="signup-required">*</span></label>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <input
                      type="email"
                      id="email"
                      placeholder="Email ID"
                      className="signup-input"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <button className="signup-verify-button" onClick={handleVerifyClick}>
                      Verify
                    </button>
                  </div>
                </div>

                {/* OTP Modal */}
                {isOtpModalOpen && (
                  <>
                    <div className="otp-backdrop" onClick={handleCloseOtpModal}></div> {/* Backdrop */}
                    <div className="otp-modal">
                      <div className="otp-modal-content">
                        <span className="otp-close-icon" onClick={handleCloseOtpModal}>
                          <FaTimes />
                        </span>
                        <h3>Enter OTP</h3>
                        <div className="otp-inputs">
                          {otp.map((digit, index) => (
                            <input
                              key={index}
                              type="text"
                              maxLength="1"
                              value={digit}
                              onChange={(e) => handleOtpChange(e, index)}
                              ref={otpRefs[index]}
                              className="otp-input"
                            />
                          ))}
                        </div>
                        {timer > 0 ? (
                          <span className="otp-timer">Time left: {timer}s</span>
                        ) : (
                          <button onClick={handleResendOtp} className="resend-otp-button">
                            Resend OTP
                          </button>
                        )}
                        {!isOtpValid && <p className="error-message">Please enter a valid 4-digit OTP.</p>}
                        <div className="otp-modal-buttons">
                          <button onClick={handleOtpSubmit} className="submit-otp-button">Submit OTP</button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="signup-input-container">
                  <label className="signup-input-label" htmlFor="country">
                    Country <span className="signup-required">*</span>
                  </label>
                  <select
                    className="signup-input"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)} // Store country_name directly
                  >
                    <option value="">Select Country</option>
                    {countries.map((countryItem) => (
                      <option key={countryItem.country_id} value={countryItem.country_name}>
                        {countryItem.country_name}
                      </option>
                    ))}
                  </select>

                </div>
                <div className="signup-input-container">
                  <label className="signup-input-label" htmlFor="user-id">
                    Entity Name
                  </label>
                  <input
                    type="text"
                    id="entity-name"
                    placeholder="Entity Name"
                    className="signup-input"
                    value={entityName}
                    onChange={(e) => setEntityName(e.target.value)}
                  />
                </div>

                <button className="signup-button" onClick={handleSignUp}>
                  Sign Up
                </button>
              </div>
            </div>
            <div className="signup-button-container">
              <span className="signup-login-already">
                Already Have Account
              </span>
              <a href="" className="signup-login-back" onClick={handleLogin}>Login</a>
            </div>
          </div>
        </div>
      </div>     
     {/* <div className='d-flex flex-row'> <NotificationManager className="align-items-end" message={notifications} /></div> */}
    </div>
  );
};

export default signupCard;
