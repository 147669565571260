import React from "react";
import "./Overview.css";
import ProductionOverview from "./ProductionOverview";
import PerformanceAlerts from "./PerformanceAlerts";
import SolarNavbar from "../navbar/SolarNavbar";

const Overview = () => {
  return (
    <div className="overview-app">
      <SolarNavbar/>
      <div className="overview">
        <div className="overview-cards">
          <ProductionOverview />
          <PerformanceAlerts /> 
        </div>
      </div>
    </div>
  );
};

export default Overview;


