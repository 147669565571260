
import SolarNavbar from "./../../navbar/SolarNavbar";
import BottomHeaderBar from "./../../navbar/BottomHeaderBar";
import { useEffect, useState } from "react";
import PlantDetailsTab from "./PlantDetailsTab";
import DevicesTab from "./DevicesTab";
import { Col, Container, Nav, NavItem, NavLink, Row, Stack, Tab } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import { Divider, } from "@mui/material";
import { green } from "@mui/material/colors";

function Styles() {
    return (
        <style>
            {`

         .button-new-variant{
         background-color: #167db0;
         color: white;
         padding: 3px 25px 3px 25px;
            }

          .button-new-variant:hover{
               background-color: #0d3a51;
         
            }

            .nav-link{
            color:black ;
            font-weight:bold
            }

        .nav-link.active {
            color: #167db0 !important;
        }

        .nav-item>a:hover {
            color: none !important;
        }

    
        `}
        </style>
    );
}

function PlantStausPage(props) {
    const [key, setKey] = useState();
    const [plant, setPlant] = useState();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.state.plant !== undefined) {
            setPlant(location.state.plant)
        }
    }, [plant])

    function addNewTicket() {
        navigate("/dashboard/maintenance/newticket", { state: { "plant": plant } });
    }

    function handlePlantAnalytic() {
        navigate("/dashboard/analytic", { state: { "plant": plant } })
    }

    return <div className="d-flex flex-column align-items-center justify-content-center">
        <SolarNavbar />
        <BottomHeaderBar />
        <Styles />
        <Tab.Container
            activeKey={key}
            onSelect={(k) => setKey(k)}
            id="left-tabs-example p-2 m-2"
            defaultActiveKey="first">
            <Container className="mw-100 px-3 m-0">
                <Row className="mw-100 mt-2 mb-2 p-0 m-0">

                    <Col className="p-0 m-0 d-flex flex-row align-items-center justify-content-start">
                        <NavLink className="px-0" href="/dashboard/plants/Plants"> <ArrowCircleLeftRoundedIcon sx={{ color: green[500], marginRight: "10px" }} /></NavLink>
                        {plant === undefined ? <span className="fw-bold ">Gayathri - AEOU-1234-EOIU-0000</span> :
                            <span>{`${plant.plantName}  ${plant.id}`}</span>
                        }

                    </Col>

                    <Col className=" p-0 m-0  d-flex flex-row align-items-center justify-content-end">
                        <button className="add-plant-button " onClick={() => addNewTicket()}>New Ticket</button>
                    </Col>
                </Row>
            </Container>
            <Container className="mw-100 px-3">
                <Stack className="m-1 mx-1" gap={0} direction="vertical">
                    <Nav as={"div"} variant="underline" className="flex-row tab-nav">
                        <NavItem >
                            <NavLink eventKey="first"> Plant Details</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink eventKey="second" >Devices</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink onClick={() => handlePlantAnalytic()}>Analytics</NavLink>
                        </NavItem>
                    </Nav>
                    <Divider className="bg-black" />
                    <Tab.Content className="w-100 mt-2 pt-2">
                        <Tab.Pane eventKey="first">  <PlantDetailsTab plant={plant} updatekey={setKey} /></Tab.Pane>
                        <Tab.Pane eventKey="second">  <DevicesTab /></Tab.Pane>
                    </Tab.Content>
                </Stack>
            </Container>
        </Tab.Container>
    </div>
}
export default PlantStausPage;