import React from "react";
import "./Reports.css";
import SolarNavbar from "../navbar/SolarNavbar";

const Reports = () => {
  return (
    <div className="reports">
      <SolarNavbar />
      <h1 className="reports-h1">Reports</h1>
    </div>
  );
};

export default Reports;
