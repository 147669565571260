// import React, { useState, useMemo } from "react";
// import "./../plant/PlantTable"; // Importing the styles

// // Importing icons from src/assets
// import updownIcon from "../../assets/updown_icon.png";
// import filterIcon from "../../assets/filter-icon1.svg";
// import settingIcon from "../../assets/Setting-icon.svg";
// import searchIcon from "../../assets/search-icon.svg";

// import { useNavigate } from "react-router-dom";
// import { Col, Form, OverlayTrigger, Popover, Row, Stack } from "react-bootstrap";
// import Paginationcontrol from "./PaginationControl";
// import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined';
// import { useTranslation } from "react-i18next";
// import { Divider } from "@mui/material";

// const PlantTable = ({ stateChanger }) => {
//   const [isDropdownVisible, setIsDropdownVisible] = useState(false);
//   const [isFilterVisible, setIsFilterVisible] = useState(false);
//   const [selectedColumns, setSelectedColumns] = useState({
//     id: true,
//     plantName: true, // Always visible and uncheckable
//     capacity: true,
//     plantType: true,
//     district: true,
//   });
//   const [selectedFilter, setSelectedFilter] = useState(""); // No filter selected by default
//   const [searchQuery, setSearchQuery] = useState(""); // Search input
//   const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
//   const [selectedRows, setSelectedRows] = useState([])
//   const [update, setUpdate] = useState(false);
//   const [tableProps, setTableProps] = useState({ rowsPerPage: 5, currentPage: 1, pageFirst: 1, pageLast: 5 })
//   const plants=['Selected','Un-Selected','Both']
//   const [plantSelect,setPlantSelect]=useState({
//     selected: false,
//     unselected:false,
//     both:false
//   })

//   const navigate = useNavigate();

//   const plantDetailsPage = (e, row) => {
//     e.preventDefault()

//     navigate("/admin/plant/edit", { state: { "plant": row } });
//   }

//   const handleDropdownToggle = () => {
//     setIsDropdownVisible(!isDropdownVisible);
//   };

//   const handleFilterToggle = () => {
//     setIsFilterVisible(!isFilterVisible);
//   };

//   const handleRowsPerPage = (value) => {
//     setRowsPerPage(value)
//   }

//   const handleFilterSelect = (filter) => {
//     setSelectedFilter(filter);
//     setIsFilterVisible(false); // Close the filter dropdown after selecting
//   };

//   const handleColumnSelect = (column) => {
//     setSelectedColumns((prev) => ({ ...prev, [column]: !prev[column] }));
//   };

//   const handleSort = (column) => {
//     let direction = "ascending";
//     if (sortConfig.key === column && sortConfig.direction === "ascending") {
//       direction = "descending";
//     }
//     setSortConfig({ key: column, direction });
//   };

//   const { t } = useTranslation(['plant']);

//   const plantNames = ["Gayitri"];

//   const plantTypes = [
//     "Residential"
//   ];

//   const capacities = [
//     "2KWH"
//   ];
//   const peakPowers = [
//     "Peak Power"
//   ];
//   const districts = ["Karimnagar"];

//   const alerts = [
//     "Normal",
//     "Major",
//     "Normal",
//     "Critical",
//   ];

//   const statuses = [
//     "Online",
//     "Offline",
//     "Partially Offline"
//   ];

//   // Generate 20 random plant entries
//   const [data, setData] = useState(() => {
//     return Array.from({ length: 20 }, (_, index) => ({
//       id: "PID220" + index + 1,
//       plantName: plantNames[Math.floor(Math.random() * plantNames.length)],
//       plantType: plantTypes[Math.floor(Math.random() * plantTypes.length)],
//       capacity: capacities[Math.floor(Math.random() * capacities.length)],
//       peakPower: peakPowers[Math.floor(Math.random() * peakPowers.length)],
//       district: districts[Math.floor(Math.random() * districts.length)],
//       alerts: alerts[Math.floor(Math.random() * alerts.length)],
//       status: statuses[Math.floor(Math.random() * statuses.length)],

//     }))
//   });

//   // Sorting logic based on the updown icon click
//   const sortedData = useMemo(() => {
//     let sortableData = [...data];
//     if (sortConfig.key) {
//       sortableData.sort((a, b) => {
//         if (a[sortConfig.key] < b[sortConfig.key]) {
//           return sortConfig.direction === "ascending" ? -1 : 1;
//         }
//         if (a[sortConfig.key] > b[sortConfig.key]) {
//           return sortConfig.direction === "ascending" ? 1 : -1;
//         }
//         return 0;
//       });
//     }
//     return sortableData;
//   }, [data, sortConfig, searchQuery]);

//   // Filtering logic (only filters if a filter is selected and a search query is entered)
//   const filteredData = useMemo(() => {
//     return sortedData.filter((row) => {
//       if (!selectedFilter || !searchQuery) return true; // No filter if no filter or search query is provided
//       return row[selectedFilter]
//         .toLowerCase()
//         .startsWith(searchQuery.toLowerCase()); // Filter matches only if it starts with the query
//     })
//   }, [update, sortConfig, searchQuery]);

//   // Pagination logic
//   const currentRows = useMemo(() => {
//     if (filteredData.length > 0) {
//       return filteredData.slice(((tableProps.currentPage - 1) * tableProps.rowsPerPage), (((tableProps.currentPage - 1) * tableProps.rowsPerPage) + tableProps.rowsPerPage))
//     }
//     return []
//   }, [filteredData, selectedRows, tableProps]);

//   const tablePropsChange = (tableProps) => {
//     setTableProps(tableProps);
//   }

//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   // Handling a maximum of 7 columns and hiding the rest in horizontal scrolling
//   const visibleColumns = Object.keys(selectedColumns).filter(
//     (key) => selectedColumns[key]
//   );

//   //handling filter and dropdown select toggle outside
//   function handleAllToggleEvents() {
//     isFilterVisible == true ? setIsFilterVisible(false) : "";
//     isDropdownVisible == true ? setIsDropdownVisible(false) : ""
//   }

//   //disable row
//   const disableRow = (e, rowData) => {
//     e.stopPropagation();
//     const index = filteredData.findIndex(dataItem => dataItem.id === rowData.id);
//     rowData.status = "offline";
//     filteredData.splice(index, 1, rowData)
//     setUpdate(!update)
//   }

//   const selectRows = (e, rowData) => {
//     e.stopPropagation();
//     const index = selectedRows.findIndex(r => r.id === rowData.id);
//     { index === -1 ? setSelectedRows([...selectedRows, rowData]) : setSelectedRows([...selectedRows.filter(r => r.id !== rowData.id)]) }
//   }

//   const emptyRows = currentRows.length !== 0 ? currentRows.length : 0;

//   return (

//     <div className="d-flex flex-column align-items-center justify-content-center px-0" style={{fontFamily:'Open Sans',fontWeight:'bold'}} onClick={() => handleAllToggleEvents()}>
//       <div className="main-container">
//         {/* Search Bar Container */}
//        <Row className="d-flex flex-row align-items-center justify-content-between">
//         <Col><span style={{fontSize:'24px' , color:'#343544'}}>Plant List</span></Col>
//         <Col className="gap-3 d-flex flex-row align-items-center justify-content-end" >
//         <div className="search-bar-container" style={{position:'relative'}}>
//             <input
//               type="text"
//               className="search-input"
//               placeholder={`Search by ${selectedFilter || "..."}`}
//               value={searchQuery}
//               onChange={(e) => setSearchQuery(e.target.value)}
//               disabled={!selectedFilter} // Disable search input until a filter is selected
//             />
//             <img src={searchIcon} alt="search" className="search-icon" />
//           </div>
//           <Divider style={{ height: 20 }} orientation="vertical" />
//           <OverlayTrigger rootClose trigger="click" placement="left-start" overlay={
//             <Popover>
//               <Popover.Body className="overlay-body-menu">
//                 <ul>
//                   {Object.keys(selectedColumns).filter((key) => selectedColumns[key] == true).map(
//                     (column) => (
//                       <li key={column} onClick={() => handleFilterSelect(column)}>
//                         {column.charAt(0).toUpperCase() + column.slice(1)}
//                       </li>
//                     )
//                   )}
//                 </ul>

//               </Popover.Body>
//             </Popover>
//           }>
//             <img
//               src={filterIcon}
//               alt="filter"
//               className="filter-icon"
//             />
//           </OverlayTrigger>

//           <Divider style={{ height: 20 }} orientation="vertical" />
//           <OverlayTrigger rootClose trigger="click" placement="auto-start" overlay={
//              <Popover>
//              <Popover.Body className="overlay-body-menu">

//                <Stack>
//                  {plants.map((s)=>{
//                  return  <label key={s}>
//                    <input
//                      type="checkbox"
//                      checked={plantSelect[s]}
//                      onChange={() => handlePlantSelect(s)}
//                    />{" "}
//                    {s.charAt(0).toUpperCase() + s.slice(1)} Plants
//                  </label>
//                  })}
//                </Stack>

//              </Popover.Body>
//            </Popover>
//           }>
//             <img
//               src={settingIcon}
//               alt="settings"
//               className="setting-icon"
//             />
//           </OverlayTrigger>

//         </Col>
//         </Row>

//         {/* Card Container */}
//         <div className="border rounded border-light-subtle">
//           <div className="table-container" style={{ overflowX: "auto" }}>
//             <table style={{ width: "100%", whiteSpace: "nowrap" }}>
//               <thead>
//                 <tr>
//                   <th></th>
//                   {visibleColumns.map((column) => (
//                     <th key={column}>
//                       {column.replace(/([A-Z])/g, " $1").charAt(0).toUpperCase() + column.replace(/([A-Z])/g, " $1").slice(1)}
//                       <img
//                         src={updownIcon}
//                         alt="sort"
//                         onClick={() => handleSort(column)}
//                         style={{ cursor: "pointer" }}
//                       />
//                     </th>
//                   ))}
//                   <th>
//                     <OverlayTrigger rootClose trigger="click" placement="auto-start" overlay={
//                       <Popover>
//                         <Popover.Body className="overlay-body">

//                           <div>
//                             <label>
//                               <input
//                                 type="checkbox"
//                                 checked={selectedColumns.id}
//                                 disabled // Plant Name is uncheckable
//                               />{" "}
//                               ID
//                             </label>
//                             {Object.keys(selectedColumns).map(
//                               (column) =>
//                                 column !== "id" && (
//                                   <label key={column}>
//                                     <input
//                                       type="checkbox"
//                                       checked={selectedColumns[column]}
//                                       onChange={() => handleColumnSelect(column)}
//                                     />{" "}
//                                     {column.charAt(0).toUpperCase() + column.slice(1)}
//                                   </label>
//                                 )
//                             )}
//                           </div>

//                         </Popover.Body>
//                       </Popover>
//                     }>
//                       <th></th>
//                     </OverlayTrigger>

//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {/* Rendering filtered rows */}

//                 {/*
//               <tr className={`${selectedRows.findIndex(rd => rd.id === rowdata.id) !== -1 ? 'row-select' : ''}`} key={rowdata.id} onClick={(e) => plantDetailsPage(e, rowdata)}>

//               //Can be used in the below TR tag
//               stateChanger(6)
//               */}

//                 {currentRows.map((rowdata, index) => (
//                   <tr className={`${selectedRows.findIndex(rd => rd.id === rowdata.id) !== -1 ? 'row-select' : ''}`} key={rowdata.id} onClick={(e) => { }}>
//                     <td className="checkbox-column">

//                     </td>
//                     {visibleColumns.map((column) => (
//                       <td key={`${rowdata.id}-${column}`} style={!isNaN(Number(rowdata[column])) ? { 'text-align': 'center' } : { 'text-align': 'left' }}>
//                         {column === "alerts" ? (
//                           <span
//                             style={{
//                               color:
//                                 rowdata.alerts === "Normal"
//                                   ? "blue"
//                                   : rowdata.alerts === "Major"
//                                     ? "orange"
//                                     : "red",
//                             }}
//                           >
//                             {rowdata[column]}
//                           </span>
//                         ) : (
//                           rowdata[column]
//                         )}
//                       </td>
//                     ))}
//                     <td className="checkbox-column">
//                       <Form>
//                         <Form.Check // prettier-ignore
//                           type='checkbox'
//                           onClick={(e) => selectRows(e, rowdata)}
//                           checked={selectedRows.findIndex(rd => rd.id === rowdata.id) !== -1 ? true : false}
//                         />
//                       </Form>

//                     </td>

//                   </tr>
//                 ))}

//                 {/* Filling empty rows to maintain table height */}
//                 {emptyRows < 0 &&
//                   Array.from({ length: emptyRows }).map((_, index) => (
//                     <tr key={`empty-${index}`}>
//                       <td colSpan="9" className="empty-row"></td>
//                     </tr>
//                   ))}
//               </tbody>
//             </table>
//             <>
//               <Paginationcontrol
//                 rowsCount={filteredData.length}
//                 tableProps={tableProps}
//                 tablePropsChange={tablePropsChange}
//                 alwaysShown={true}
//               />
//             </>
//           </div>
//         </div>
//       </div>
//     </div >
//   );
// };

// export default PlantTable;

import React, { useState, useMemo, useEffect, useContext } from "react";
import "./../plant/PlantTable"; // Importing the styles
import axios from "axios";
import updownIcon from "../../assets/updown_icon.png";
import filterIcon from "../../assets/filter-icon1.svg";
import settingIcon from "../../assets/Setting-icon.svg";
import searchIcon from "../../assets/search-icon.svg";
import { MessageContext } from "../../App";
import { useNavigate } from "react-router-dom";
import {
  Col,
  Form,
  OverlayTrigger,
  Popover,
  Row,
  Stack,
} from "react-bootstrap";
import Paginationcontrol from "./PaginationControl";
import { useTranslation } from "react-i18next";

const PlantTable = ({ stateChanger, onSelectPlant, selectedPlantIds = [] }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState({
    plantId: true,
    plantName: true,
    plantType: true,
    plantCategory: false,
    capacity: true,
    capacityUnit: false,
    country: true,
    region: false,
    state: false,
    district: false,
    pincode: false,
    longitude: false,
    latitude: false,
    installDate: false,
    azimuthAngle: false,
    tiltAngle: false,
    ownerFirstName: false,
    ownerLastName: false,
    ownerEmail: false,
  });
  const [selectedFilter, setSelectedFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  // const [selectedRows, setSelectedRows] = useState([]);
  const [tableProps, setTableProps] = useState({
    rowsPerPage: 5,
    currentPage: 1,
    pageFirst: 1,
    pageLast: 5,
  });
  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(false);
  const onMessageChange = useContext(MessageContext);

  const navigate = useNavigate();
  const { t } = useTranslation(["plant"]);

  const [selectedRows, setSelectedRows] = useState(
    data.filter(row => selectedPlantIds.includes(row.plantId))
  );

  // const [selectedRows, setSelectedRows] = useState([]);

  // useEffect(() => {
  //   // Initialize selectedRows based on selectedPlantIds when data changes
  //   setSelectedRows(data.filter(row => selectedPlantIds.includes(row.plantId)));
  // }, [data, selectedPlantIds]);

  // Initialize selected rows based on selectedPlantIds only once after data loads
  // Initialize selectedRows only once based on selectedPlantIds after data loads
  useEffect(() => {
    if (data.length > 0 && selectedPlantIds.length > 0) {
      setSelectedRows(data.filter((row) => selectedPlantIds.includes(row.plantId)));
    }
  }, [data, selectedPlantIds]);

  // Toggle checkbox selection
  const handleRowSelection = (rowData) => {
    const isSelected = selectedRows.some((row) => row.plantId === rowData.plantId);
    const updatedSelectedRows = isSelected
      ? selectedRows.filter((row) => row.plantId !== rowData.plantId)
      : [...selectedRows, rowData];

    setSelectedRows(updatedSelectedRows);

    // Notify parent component of updated selection
    if (onSelectPlant) {
      onSelectPlant(updatedSelectedRows.map((row) => row.plantId));
    }
  };

  // const selectRows = (e, rowData) => {
  //   e.stopPropagation();
  //   const index = selectedRows.findIndex((r) => r.plantId === rowData.plantId);
  //   const updatedSelectedRows =
  //     index === -1
  //       ? [...selectedRows, rowData]
  //       : selectedRows.filter((r) => r.plantId !== rowData.plantId);

  //   setSelectedRows(updatedSelectedRows);

  //   if (onSelectPlant) {
  //     onSelectPlant(updatedSelectedRows.map((row) => row.plantId));
  //   }
  // };


  // const selectRows = (e, rowData) => {
  //   e.stopPropagation();
  //   const index = selectedRows.findIndex((r) => r.plantId === rowData.plantId);
  //   const updatedSelectedRows =
  //     index === -1
  //       ? [...selectedRows, rowData]
  //       : selectedRows.filter((r) => r.plantId !== rowData.plantId);

  //   setSelectedRows(updatedSelectedRows);

  //   // Call the callback with updated selected rows
  //   if (onSelectPlant) {
  //     onSelectPlant(updatedSelectedRows.map((row) => row.plantId));
  //   }
  // };

  const fetchData = async () => {
    try {
      const entityId =
        JSON.parse(sessionStorage.getItem("userData"))?.entityId || "";
      const authToken = sessionStorage.getItem("authToken");

      if (!entityId) {
        console.warn(
          "No entity ID found. Ensure session storage contains 'userData' with 'entityId'."
        );
        return;
      }

      const response = await axios.get(
        `https://solar-api.antsai.in/api/plant/fetchPlantList?entityid=${entityId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (Array.isArray(response.data)) {
        const formattedData = response.data.map((item) => ({
          plantId: item["Plant ID"],
          plantName: item["Plant Name"],
          plantType: item["Plant Type"],
          plantCategory: item["Plant Category"],
          capacity: item["Capacity"],
          capacityUnit: item["Capacity Unit"],
          country: item["Country"],
          region: item["Region"],
          state: item["State"],
          district: item["District"],
          pincode: item["Pincode"],
          longitude: item["Longitude"],
          latitude: item["Latitude"],
          installDate: item["Install Date"],
          azimuthAngle: item["Azimuth Angle"],
          tiltAngle: item["Tilt Angle"],
          ownerFirstName: item["Owner First Name"],
          ownerLastName: item["Owner Last Name"],
          ownerEmail: item["Owner Email"],
        }));
        setData(formattedData);
      } else {
        console.error("Unexpected data format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleFilterSelect = (filter) => {
    setSelectedFilter(filter);
    setIsFilterVisible(false);
  };

  const handleColumnSelect = (column) => {
    setSelectedColumns((prev) => ({ ...prev, [column]: !prev[column] }));
  };

  const handleSort = (column) => {
    let direction = "ascending";
    if (sortConfig.key === column && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: column, direction });
  };

  const sortedData = useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig, searchQuery]);

  const filteredData = useMemo(() => {
    return sortedData.filter((row) => {
      if (!selectedFilter || !searchQuery) return true;
      return row[selectedFilter]
        .toLowerCase()
        .startsWith(searchQuery.toLowerCase());
    });
  }, [sortedData, selectedFilter, searchQuery]);

  const currentRows = useMemo(() => {
    if (filteredData.length > 0) {
      return filteredData.slice(
        (tableProps.currentPage - 1) * tableProps.rowsPerPage,
        tableProps.currentPage * tableProps.rowsPerPage
      );
    }
    return [];
  }, [filteredData, tableProps]);

  const tablePropsChange = (tableProps) => {
    setTableProps(tableProps);
  };

  // const selectRows = (e, rowData) => {
  //   e.stopPropagation();
  //   const index = selectedRows.findIndex((r) => r.plantId === rowData.plantId);
  //   setSelectedRows(
  //     index === -1
  //       ? [...selectedRows, rowData]
  //       : selectedRows.filter((r) => r.plantId !== rowData.plantId)
  //   );
  // };

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center px-0"
      style={{ fontFamily: "Open Sans", fontWeight: "bold" }}
    >
      <div className="main-container">
        <Row className="d-flex flex-row align-items-center justify-content-between">
          <Col>
            <span style={{ fontSize: "24px", color: "#343544" }}>
              Plant List
            </span>
          </Col>
          <Col className="gap-3 d-flex flex-row align-items-center justify-content-end">
            <div
              className="search-bar-container"
              style={{ position: "relative" }}
            >
              <input
                type="text"
                className="search-input"
                placeholder={`Search by ${selectedFilter || "..."}`}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                disabled={!selectedFilter}
              />
              <img src={searchIcon} alt="search" className="search-icon" />
            </div>
            <OverlayTrigger
              rootClose
              trigger="click"
              placement="left-start"
              overlay={
                <Popover>
                  <Popover.Body className="overlay-body-menu">
                    <ul>
                      {Object.keys(selectedColumns)
                        .filter((key) => selectedColumns[key])
                        .map((column) => (
                          <li
                            key={column}
                            onClick={() => handleFilterSelect(column)}
                          >
                            {column.charAt(0).toUpperCase() + column.slice(1)}
                          </li>
                        ))}
                    </ul>
                  </Popover.Body>
                </Popover>
              }
            >
              <img src={filterIcon} alt="filter" className="filter-icon" />
            </OverlayTrigger>

            <OverlayTrigger
              rootClose
              trigger="click"
              placement="auto-start"
              overlay={
                <Popover>
                  <Popover.Body className="overlay-body">
                    <div>
                      {Object.keys(selectedColumns).map((column) => (
                        <label key={column}>
                          <input
                            type="checkbox"
                            checked={selectedColumns[column]}
                            onChange={() => handleColumnSelect(column)}
                          />{" "}
                          {column.charAt(0).toUpperCase() + column.slice(1)}
                        </label>
                      ))}
                    </div>
                  </Popover.Body>
                </Popover>
              }
            >
              <img src={settingIcon} alt="settings" className="setting-icon" />
            </OverlayTrigger>
          </Col>
        </Row>

        <div className="border rounded border-light-subtle">
          <div className="table-container" style={{ overflowX: "auto" }}>
            <table style={{ width: "100%", whiteSpace: "nowrap" }}>
              <thead>
                <tr>
                  {Object.keys(selectedColumns)
                    .filter((key) => selectedColumns[key])
                    .map((column) => (
                      <th key={column} onClick={() => handleSort(column)}>
                        {column
                          .replace(/([A-Z])/g, " $1")
                          .replace(/^\w/, (c) => c.toUpperCase())}
                        <img
                          src={updownIcon}
                          alt="sort"
                          style={{ cursor: "pointer" }}
                        />
                      </th>
                    ))}
                  {/* <th>Select</th> */}
                </tr>
              </thead>
              <tbody>
                {currentRows.map((row) => (
                  <tr key={row.plantId}>
                    {Object.keys(selectedColumns)
                      .filter((key) => selectedColumns[key])
                      .map((column) => (
                        <td key={`${row.plantId}-${column}`}>{row[column]}</td>
                      ))}
                    <td className="checkbox-column">
                    <Form.Check
                  type="checkbox"
                  checked={selectedRows.some((selectedRow) => selectedRow.plantId === row.plantId)}
                  onChange={() => handleRowSelection(row)}
                />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Paginationcontrol
              rowsCount={filteredData.length}
              tableProps={tableProps}
              tablePropsChange={tablePropsChange}
              alwaysShown={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlantTable;
