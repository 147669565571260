import React, { useState, useEffect, useRef, useContext } from "react"; // Unified import for all hooks
import { Button, Col, Container, Form, Modal, Row, Stack } from "react-bootstrap";
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import blueSolidLeftIcon from "../../assets/blue_solid_left.png";
import axios from 'axios';
import { MessageContext } from "../../App";


function Styles() {
    return (
        <style>{`
               .row-card{
                 background-color:#f2f5f7;
                 text-align: left;
                 align-items: center;
                 justify-content-center;
                 box-shadow: 0px 0px 3px 1px #d7dadb;
                 border-radius: 5px;
               }

               .fw-bold{
                    font-weight:700px;
                    font-size:22px;
                    letter-spacing: 2px;
                    align-items: center;
                }

               .form-button{
                   background-color: #167db0;
                   color: white;
                   border-radius: 7px;
                   padding: 3px 20px 3px 20px
                }

                .form-button:hover{
                background-color: white !important;
                color : black !important;
                border-color:#167db0 !important;
                }

                .form-button-sp{
                    padding: 1px 10px 1px 10px !important;
                    border-radius: 20px !important;
                }

              .card-height{
               height: 88% !important
              }          
               
              .entity-add-form{
                    font-family: 'Open Sans';
                    font-weight: normal;
                } 
                .entity-add-form .row{
                align-items: center;
                }
                .entity-add-form .form-label{
                 font-weight:bold;
                 margin: 0px;
                }
                 .entity-add-form .form-select{
                 font-weight:normal !important;
                 font-family: 'Open Sans';
                }

                .entity-add-form .form-button{
                  font-family: 'Open Sans';
                    font-weight: bold;
                }
               .entity-add-form .form-control{
                  font-family: 'Open Sans';
                    font-weight: normal;
                }
               .entity-add-form .form-check-input{
                   box-shadow: 0px 1px 3px #00000029;
                   border: 1px solid #0F0D0D;
                   border-radius: 5px;
                   opacity: 1;
                   width: 20px;
                   height: 20px;

               }
             .entity-form-left-arrow{
               width: 23px;
               height: 23px;
               opacity: 1;
               margin-right: 5px;
               }
        `}

        </style>
    );
}

function EntityEditPage({ rowId }) {
    const { entityId } = useParams();
    const [country, setCountry] = useState([])
    const [regions, setRegions] = useState([])
    const [states, setStates] = useState([])
    const [districts, setDistricts] = useState([])
    const [entityTypes, setEntityTypes] = useState([])
    const { t } = useTranslation('entity')
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [validated, setValidated] = useState(false);
    const [errors, setErrors] = useState({});
    const onMessageChange = useContext(MessageContext);
    const [formData, setFormData] = useState({
        entityId: "",
        entityName: "",
        category: "",
        ownerFirstName: "",
        ownerLastName: "",
        email: "",
        mobile: "",
        country: "",
        region: "",
        state: "",
        district: "",
        pincode: "",
        addressLine1: "",
        addressLine2: "",
        gstin: "",
        disableEntity: false
    })

    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const sessionEntityId = userData?.entityId || ""; // Use a distinct name
  

    useEffect(() => {
        // Fetch entity data
        const fetchEntityData = async () => {
            try {
                const response = await fetch(`https://solar-api.antsai.in/api/entity/getEntityDetails?entityid=${rowId}`);
                const data = await response.json();

                console.log('Form Data Fetched:', data);

                setFormData({
                    entityId: data["Entity ID"],
                    entityName: data["Entity Name"],
                    category: data["Category"],
                    ownerFirstName: data["Owners First Name"],
                    ownerLastName: data["Owners Last Name"],
                    email: data["Email Id"],
                    mobile: data["Mobile"],
                    country: data["Country"],
                    region: data["Region"],
                    state: data["State"],
                    district: data["District"],
                    pincode: data["Pincode"],
                    addressLine1: data["Address Line1"],
                    addressLine2: data["Address Line2"],
                    gstin: data["GSTIN"],
                    disableEntity: data["Disable Entity"] === 1 ? true : false,
                });
            } catch (error) {
                console.error("Error fetching entity data:", error);
            }
        };

        fetchEntityData();
    }, [entityId]);

// Fetch category options using sessionEntityId
useEffect(() => {
    const fetchCategoryOptions = async () => {
        try {
            const response = await fetch(`https://solar-api.antsai.in/api/categories?entityid=${sessionEntityId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            if (Array.isArray(data.categories)) {
                setCategoryOptions(data.categories);
            } else {
                console.error("Unexpected data format:", data);
            }
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    fetchCategoryOptions();
}, [sessionEntityId]);

// Fetch countries from API
useEffect(() => {
    const fetchCountries = async () => {
        try {
            const response = await fetch('https://solar-api.antsai.in/api/countries', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({}),
            });
            if (response.ok) {
                const data = await response.json();
                console.log('Fetched countries:', data);
                setCountry(data);
            } else {
                console.error(`Failed to fetch countries: ${response.status} ${response.statusText}`);
            }
        } catch (error) {
            console.error("Error fetching countries:", error);
        }
    };

    fetchCountries();
}, []);

// Fetch regions based on selected country (Scenario 1)
useEffect(() => {
    if (!formData.country) return;

    const selectedCountry = country.find((c) => c.country_name === formData.country);
    if (!selectedCountry) return;

    const fetchRegions = async () => {
        try {
            const response = await fetch(`https://solar-api.antsai.in/api/Region1?country_id=${selectedCountry.country_id}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            });
            const data = await response.json();
            if (Array.isArray(data)) {
                setRegions(data.map((region) => region.region));
            } else {
                console.error("Unexpected data format:", data);
                setRegions([]); // Set to empty array if data format is unexpected
            }
            console.log("Fetched regions:", data); // Debugging statement
        } catch (error) {
            console.error("Error fetching regions:", error);
            setRegions([]); // Ensure `regions` is reset on error
        }
    };

    fetchRegions();
}, [formData.country, country]);

// Fetch states based on selected country (if no region selected)
useEffect(() => {
    if (!formData.country) return;
    
    const selectedCountry = country.find((c) => c.country_name === formData.country);
    if (!selectedCountry) return;

    const fetchStates = async () => {
        try {
            const response = await fetch(`https://solar-api.antsai.in/api/state1/${selectedCountry.country_id}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            });
            const data = await response.json();
            if (Array.isArray(data)) {
                setStates(data);
            } else {
                console.error("Unexpected data format:", data);
                setStates([]); // Ensure states is empty if data format is unexpected
            }
            console.log("Fetched states:", data); // Debugging statement
        } catch (error) {
            console.error("Error fetching states:", error);
            setStates([]); // Ensure `states` is reset on error
        }
    };

    fetchStates();
}, [formData.country, country]);

//fetch states by region
useEffect(()=>{
    if (!formData.country) return;
    
    const selectedCountry = country.find((c) => c.country_name === formData.country);    
    if (!selectedCountry) return;

    const fetchStates = async () => {
        try {
            const url=`https://solar-api.antsai.in/api/state2?country_id=${selectedCountry.country_id}&region=${formData.region}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });
            if(response.status ===200){
            const data = await response.json();
            if (Array.isArray(data['states'])) {
                setStates(data['states']);
            } else {
                console.error("Unexpected data format:", data);
                setStates([]); // Ensure states is empty if data format is unexpected
            }
            console.log("Fetched states:", data);
         } // Debugging statement
        } catch (error) {
            console.error("Error fetching states:", error);
            setStates([]); // Ensure `states` is reset on error
        }
    };

    fetchStates();
},[formData.region])

// Region fetching function when state is selected
useEffect(()=>{
    
const fetchRegionByState = async () => {
    const selectedCountry = country.find((c) => c.country_name === formData.country);
    if (!selectedCountry) return;

    try {
        const response = await fetch(`https://solar-api.antsai.in/api/Region2?country_id=${selectedCountry.country_id}&state_name=${formData.state}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });
        const regionData = await response.json();
        if (regionData && regionData.region) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                region: regionData.region
            }));
        } else {
            console.error("Unexpected data format for region by state:", regionData);
        }
    } catch (error) {
        console.error("Error fetching region by state:", error);
    }
};
fetchRegionByState()
},[formData.state])

// Fetch region based on selected country and state (Scenario 2)
// useEffect(() => {
//     if (!formData.country || !formData.state) return;
//     const selectedCountry = country.find((c) => c.country_name === formData.country);
//     const selectedState = formData.state;
//     if (!selectedCountry || !selectedState) return;

//     const fetchRegionByState = async () => {
//         try {
//             const response = await fetch(`https://solar-api.antsai.in/api/Region2?country_id=${selectedCountry.country_id}&state_name=${selectedState}`, {
//                 method: 'GET',
//                 headers: { 'Content-Type': 'application/json' },
//             });
//             const regionData = await response.json();
//             if (regionData && regionData.region) {
//                 setFormData((prev) => ({ ...prev, region: regionData.region }));
//             } else {
//                 console.error("Unexpected data format for region by state:", regionData);
//             }
//         } catch (error) {
//             console.error("Error fetching region by state:", error);
//         }
//     };

//     fetchRegionByState();
// }, [formData.country, formData.state, country]);

    useEffect(() => {
        if (formData.state === "") return
        // Fetch states based on selected state

        fetchDistricts()
    }, [formData.state,states]);
    // Fetch districts based on selected state
    const fetchDistricts = async () => {
        try {
            const response = await fetch(
                `https://solar-api.antsai.in/api/districts/${states.find(
                    (c) => c.state_name === formData.state
                ).state_id}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    //   body: JSON.stringify({ country_id: selectedCountry.country_id }),
                }
            );


            if (response.ok) {
                const data = await response.json();
                console.log('Fetched districts:', data);
                setDistricts(data);
            } else {
                console.error(`Failed to fetch districts: ${response.status} ${response.statusText}`);
            }
        } catch (error) {
            console.error("Error fetching districts:", error);
        }
    };

    const handleChange = (event) => {
        const { name, value, type, checked, files } = event.currentTarget;
    
        let validValue = value;
        if (name === "mobile" && !/^\d{0,15}$/.test(value)) return; // Allow only numbers, up to 15 digits
        if (name === "pincode" && !/^\d{0,6}$/.test(value)) return; // Allow only numbers, up to 6 digits
        if (name === "gstin") {
            // Limit GSTIN to 15 characters and convert to uppercase
            validValue = value.toUpperCase().slice(0, 15);
        }
        if ((name === "addressLine1" || name === "addressLine2") && value.length > 50) {
          onMessageChange({
            type: "Warning",
            info: "Address line should be within 50 characters.",
          });
          return; // Limit address to 50 characters
        }
    
        setFormData((prevData) => ({
          ...prevData,
          [name]:
            type === "checkbox" ? checked : type === "file" ? files : validValue,
        }));
      };

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }

        // Prepare data for PUT request
        const payload = {
            "entityid": formData.entityId,
            "entityname": formData.entityName,
            "category": formData.category,
            "contactfirstname": formData.ownerFirstName,
            "contactlastname": formData.ownerLastName,
            "email": formData.email,
            "mobile": formData.mobile,
            "country": formData.country,
            "state": formData.state,
            "district": formData.district,
            "pincode": formData.pincode,
            "address_line_1": formData.addressLine1,
            "address_line_2": formData.addressLine2,
            "GSTIN": formData.gstin,
            "Region": formData.region,
            "disable": formData.disableEntity ? 1 : 0
        };

        try {
            const response = await axios.put('https://solar-api.antsai.in/api/entity/edit', payload);
            if (response.status === 200) {
                // Handle success, maybe show a success message or redirect
                alert("Entity updated successfully.");
            } else {
                // Handle error
                alert("Failed to update entity.");
            }
        } catch (error) {
            console.error("Error updating entity:", error);
            alert("An error occurred while updating the entity.");
        }
    };

    const refreshPage = () => {
        window.location.reload(); // Or define any other logic you want here
    };

    return (
        <div className="d-flex flex-column align-items-center justify-content-center">
            <Container className="mw-100">
                <Styles />
                <Row className="mt-2 mb-2">

                    <Col className="d-flex flex-row align-items-center justify-content-start">
                        <NavLink className="text-decoration-none d-flex align-items-center" onClick={refreshPage}>
                            <img
                                className="entity-form-left-arrow"
                                src={blueSolidLeftIcon}
                                alt="sort"
                                style={{ cursor: "pointer" }}
                            />
                            <span className=" text-decoration-none text-black" style={{fontFamily:' Lato black'}}>{t('entity:edit')} - {formData.entityName}</span>
                        </NavLink>

                    </Col>

                    <Col className="d-flex flex-row align-items-center justify-content-end">

                    </Col>
                </Row>
            </Container>
            <Container className="mw-100">
                <Form className="w-100 p-0 m-0 entity-add-form" noValidate validated={validated} onSubmit={handleSubmit}>
                    <div className=" d-flex flex-column align-items-center justify-content-center w-80 p-0 mt-2">
                        <Row className="gap-2 w-100 row-card p-4 m-2 d-flex flex-lg-row  flex-column">
                            <Col>
                                <Stack as="row" gap={2} direction="vertical" >
                                    <Col>
                                    <Form.Group controlId="entityId">
    <Row className="w-100">
        <Col md={3}>
            <Form.Label className="text-nowrap">{t('entity.id')} <span>:</span></Form.Label>
        </Col>
        <Col md={9}>
            <Form.Control
                type="text"
                value={formData.entityId}
                disabled
                readOnly
                className="text-nowrap fw-normal"
                placeholder="Entity ID"
            />
        </Col>
    </Row>
</Form.Group>
                                    </Col>
                                    <Col span>

                                        <Form.Group controlId="entityName">
                                            <Row className="w-100">
                                                <Col md={3}>
                                                    <Form.Label className="text-nowrap">{t('entity.name')} <StarOutlinedIcon style={{ color: 'red', fontSize: 'smaller' }} />  <spa>:</spa></Form.Label>
                                                </Col><Col md={9} >
                                                    <Form.Control 
                                                    name="entityName" 
                                                    value={formData.entityName} 
                                                    required type="string" 
                                                    onChange={handleChange} 
                                                    placeholder="Entity name"
                                                    disabled
                                                    readOnly 
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>

                                    </Col>
                                    <Col>
                                        <Form.Group controlId="entityCategory">
                                            <Row className="w-100">
                                                <Col md={3}>
                                                    <Form.Label className="text-nowrap">{t('entity:entityCategory')} <StarOutlinedIcon style={{ color: 'red', fontSize: 'smaller' }} /> <spa>:</spa></Form.Label>
                                                </Col>
                                                <Col md={9}>
                                                    <Form.Select
                                                        name="category"
                                                        value={formData.category}
                                                        onChange={handleChange}
                                                        required
                                                        size="md"
                                                    >
                                                        {/* {formData.category ==='' ? <option value="">{t('entity:selectCategory')}</option> : ''} */}
                                                        {categoryOptions.map((category) => (
                                                            <option key={category} value={category}>{category}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                    <Form.Group controlId="gstin">
    <Row className="w-100">
        <Col md={3}>
            <Form.Label className="text-nowrap">{t('entity:gstin')} <span>:</span></Form.Label>
        </Col>
        <Col md={9}>
            <Form.Control 
                name="gstin" 
                value={formData.gstin} 
                required 
                type="text" 
                onChange={(e) => handleChange(e)}
                placeholder="Enter GST/VAT Number" 
            />
            <Form.Control.Feedback type="invalid">
                {errors.gstin}
            </Form.Control.Feedback>
        </Col>
    </Row>
</Form.Group>

                                    </Col>
                                </Stack>
                            </Col>
                            <Col>
                                <Stack gap={3} direction="vertical">
                                <Form.Group controlId="ownerFirstName">
    <Row className="w-100">
        <Col md={3}>
            <Form.Label className="text-nowrap">
                {t('entity:ownerFirstName')} <StarOutlinedIcon style={{ color: 'red', fontSize: 'smaller' }} /> <span>:</span>
            </Form.Label>
        </Col>
        <Col md={9}>
            <Form.Control 
                name="ownerFirstName" 
                value={formData.ownerFirstName} 
                required 
                type="text" 
                onChange={(e) => handleChange(e)}
                placeholder="Owner's First Name"
            />
            <Form.Control.Feedback type="invalid">
                {errors.ownerFirstName}
            </Form.Control.Feedback>
        </Col>
    </Row>
</Form.Group>

<Form.Group controlId="ownerLastName">
    <Row className="w-100">
        <Col md={3}>
            <Form.Label className="text-nowrap">
                {t('entity:ownerLastName')} <StarOutlinedIcon style={{ color: 'red', fontSize: 'smaller' }} /> <span>:</span>
            </Form.Label>
        </Col>
        <Col md={9}>
            <Form.Control 
                name="ownerLastName" 
                value={formData.ownerLastName} 
                required 
                type="text" 
                onChange={(e) => handleChange(e)}
                placeholder="Owner's Last Name"
            />
            <Form.Control.Feedback type="invalid">
                {errors.ownerLastName}
            </Form.Control.Feedback>
        </Col>
    </Row>
</Form.Group>

                                    <Form.Group controlId="formEmail">
                                        <Row gap={2} className="w-100">
                                            <Col md={3}>
                                                <Form.Label className="text-nowrap">Email <spa>:</spa></Form.Label>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Control
                                                    type="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    disabled
                                                    readOnly // This locks the email field
                                                    // placeholder="Enter email"
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group controlId="mobile">
            <Row className="w-100">
                <Col md={3}>
                    <Form.Label className="text-nowrap">Mobile <span>:</span></Form.Label>
                </Col>
                <Col md={9}>
                    <Form.Control
                        name="mobile"
                        value={formData.mobile}
                        required
                        type="text"
                        onChange={(e) => handleChange(e)}
                        placeholder="Enter mobile"
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.mobile}
                    </Form.Control.Feedback>
                </Col>
            </Row>
        </Form.Group>
                                </Stack>
                            </Col>
                        </Row>

                        <Row className="gap-2 w-100 row-card p-4 m-2 d-flex flex-lg-row  flex-column">
                            <Col>
                                <Stack as="row" gap={2} direction="vertical" >
                                    <Col>
                                        <Form.Group controlId="country">
                                            <Row className="w-100">

                                                <Col md={3}>
                                                    <Form.Label className="text-nowrap">Country <StarOutlinedIcon style={{ color: 'red', fontSize: ' xx-small' }} /> <spa>:</spa></Form.Label>
                                                </Col>
                                                <Col md={9}>
                                                    <Form.Select name="country" value={formData.country} onChange={handleChange} placeholder={formData.country === "" ? "Entity country" : formData.country} required size="md">
                                                        <option value="">{t('entity:selectcountry')}</option>
                                                        {country.map((country) => {
                                                            return <option key={country.country_id} value={country.country_name}>
                                                                {country.country_name}
                                                            </option>
                                                        })}
                                                    </Form.Select>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                    <Col span>
                                    <Form.Group controlId="region">
                                        <Row className="w-100">
                                            <Col md={3}>
                                                <Form.Label className="text-nowrap">
                                                    {t('entity:region')} <spa>:</spa>
                                                </Form.Label>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Select
                                                    name="region"
                                                    value={formData.region}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        // Fetch region automatically if state selected
                                                        // if (formData.region !== '') {
                                                        //     fetchStatesByRegion();
                                                        // }
                                                        setFormData((prevFormData) => ({
                                                            ...prevFormData,
                                                            ['state']: '',
                                                        }));
                                                    }}
                                                    required
                                                    size="md"
                                                    disabled={regions.length === 0} // Disable if no regions available
                                                >
                                                    {/* <option value="">{t('entity:selectRegion')}</option> */}
                                                    {regions.map((region, index) => (
                                                        <option key={index} value={region}>
                                                            {region}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                                {regions.length === 0 && <small className="text-muted">No regions available</small>}
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    </Col>
                                    <Col>
                                    {/* Rendering the state dropdown only if states are available */}
                                    {/* // State dropdown */}
                                    <Form.Group controlId="state">
                                        <Row className="w-100">
                                            <Col md={3}>
                                                <Form.Label className="text-nowrap">
                                                    {t('entity:state')} <StarOutlinedIcon style={{ color: 'red', fontSize: 'smaller' }} /> <spa>:</spa>
                                                </Form.Label>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Select
                                                    name="state"
                                                    value={formData.state} // Ensure `formData.state` is bound to `value`
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        // Fetch region automatically if state selected
                                                        // if (!formData.region) {
                                                        //     fetchRegionByState(formData.country, e.target.value);
                                                        // }
                                                     
                                                    }}
                                                    required
                                                    size="md"
                                                    disabled={states.length === 0}
                                                >
                                                  {formData.state === '' ? <option value="">{t('entity:selectstate')}</option> : ""}
                                                    {states.map((state, index) => (
                                                        <option key={state.state_id || index} value={state.state_name}>
                                                            {state.state_name}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                                {states.length === 0 && <small className="text-muted">No states available</small>}
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="district">
                                            <Row className="w-100">
                                                <Col md={3}>
                                                    <Form.Label className="text-nowrap">{t('entity:district')} <StarOutlinedIcon style={{ color: 'red', fontSize: 'smaller' }} />  <spa>:</spa></Form.Label>
                                                </Col>
                                                <Col md={9}>
                                                    <Form.Select name="district" value={formData.district} onChange={handleChange} required placeholder={formData.district === "" ? "Entity district" : formData.district} size="md">
                                                        <option value="">{t('entity:selectdistrict')}</option>
                                                        {districts.map((district) => {
                                                            return <option key={district.district_id} value={district.district_name}>
                                                                {district.district_name}
                                                            </option>
                                                        })}
                                                    </Form.Select>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="pincode">
                                            <Row className="w-100">
                                                <Col md={3}>
                                                    <Form.Label className="text-nowrap">{t('entity:pincode')} <StarOutlinedIcon style={{ color: 'red', fontSize: 'smaller' }} />  <spa>:</spa></Form.Label>
                                                </Col><Col md={9} >
                                                    <Form.Control 
                                                    name="pincode" 
                                                    value={formData.pincode} 
                                                    required type="string" 
                                                    onChange={(e) => handleChange(e)}
                                                    placeholder="Enter Pincode" />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                    <Col>

                                        <Form.Group controlId="disableEntity">
                                            <Row className="w-100">
                                                <Col md={3}>
                                                    <Form.Label className="text-nowrap">{t('user:disableUser')} <spa>:</spa></Form.Label>
                                                </Col>
                                                <Col md={9} >
                                                    <Form.Check
                                                        className="entity-edit-check"
                                                        name="disableEntity"
                                                        checked={formData.disableEntity}
                                                        onChange={handleChange}
                                                        type="checkbox"
                                                        id={"disableEntity"}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Stack>
                            </Col>
                            <Col>
                                <Stack gap={3} direction="vertical">
                                    <Form.Group controlId="addressLine1">
                                        <Row className="w-100 align-items-baseline">

                                            <Col md={3}>
                                                <Form.Label className="text-nowrap">{t('entity:addressLine1')} <spa>:</spa></Form.Label>
                                            </Col><Col md={9} >
                                                <Form.Control 
                                                name="addressLine1" 
                                                value={formData.addressLine1} as="textarea" rows={3} 
                                                onChange={(e) => handleChange(e)}
                                                placeholder="Enter address" />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group controlId="addressLine2">
                                        <Row gap={2} className="w-100 align-items-baseline">

                                            <Col md={3}>
                                                <Form.Label className="text-nowrap">{t('entity:addressLine2')}  <spa>:</spa></Form.Label>
                                            </Col><Col md={9} >
                                                <Form.Control 
                                                name="addressLine2" 
                                                value={formData.addressLine2} as="textarea" rows={3} 
                                                onChange={(e) => handleChange(e)}
                                                placeholder="Enter address" />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <div className="p-2"></div>
                                    <div className="p-2"></div>
                                </Stack>
                            </Col>
                        </Row>

                        <Row className="w-100">
                            <div className="w-100 d-flex flex-row align-items-center justify-content-end">
                                <Button type="submit" className="ml-3 m-1 form-button">Update</Button>
                            </div>
                        </Row>
                    </div>
                </Form>
            </Container>
        </div>
    )
}
export default EntityEditPage;