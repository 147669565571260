import React, {useState, useContext } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // For eye icons
import "./Login.css";
import backgroundImage from "../assets/solar-dl-login-bg-2x.png"; // Adjust the path according to your project structure
import logo from "../assets/logog.svg";
import { MessageContext } from '../App';

const LoginCard = () => {
  const navigate = useNavigate(); // Initialize the navigate function
  const [email, setEmail] = useState('');
  // Handle password visibility
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false); // Password visibility toggle

  const onMessageChange = useContext(MessageContext);

  const handleLogin = async () => {
    if (!email || !password) {
      // alert("Please enter both email and password.");
      onMessageChange({ type: 'Danger', 'info': 'Please enter both email and password.' })
      return;
    }
  
    const payload = {
      email,
      password,
    };
  
    try {
      const response = await fetch("https://solar-api.antsai.in/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        const data = await response.json();
  
        // Check if userData and token exist in response
        if (data.userData && data.userData.token) {
          // Save user data and token to sessionStorage
          sessionStorage.setItem("userData", JSON.stringify(data.userData));
          sessionStorage.setItem("authToken", data.userData.token);
             // Log user data from session storage
        console.log("User data stored in sessionStorage:", JSON.parse(sessionStorage.getItem("userData")));
          // alert(data.message); // Displays "Login successful!"
          onMessageChange({ type: 'Success', 'info': data.message })
          navigate("/dashboard/overview");
        } else {
          // alert("Login failed: No user data found in response.");
          onMessageChange({ type: 'Danger', 'info': 'Login failed: No user data found in response.' })
        }
      } else {
        const errorData = await response.json();
        // alert(`Login failed: ${errorData.message}`);
        onMessageChange({
          type: 'Danger', 
          info: `Login failed: ${errorData.message}`
        });
      }
    } catch (error) {
      console.error("Error during login:", error);
      // alert("An error occurred. Please try again.");
      onMessageChange({ type: 'Danger', 'info': 'An error occurred. Please try again.' })
    }
  };

  const handleSignup = () => {
    // Perform login validation here (e.g., check if fields are filled)

    // Redirect to the /dashboard/overview route
    navigate("/signup/Signup");
  };

  const handleForgotpassword = () => {
    // Perform login validation here (e.g., check if fields are filled)

    // Redirect to the /dashboard/overview route
    navigate("/forgotpassword/antsfp");
  };

  // Handle password visibility toggle
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <div
      className="login-card-container"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="login-card">
      
        <img 
          src={logo}
          className="login-logo"
        />
        <h2 className="login-title">
          <span>LOGIN</span>
        </h2>
        
        <div className="login-input-container">
          <label className="login-input-label" htmlFor="user-id">
            User ID
          </label>
          <input
            type="text"
            id="user-id"
            placeholder="Email"
            className="login-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="login-input-container">
          <label className="login-input-label" htmlFor="password">Password</label>
          <div style={{ position: 'relative' }}>
            <input
              type={isPasswordVisible ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
              className="login-input"
            />
            <span
              onClick={togglePasswordVisibility}
              style={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                color: '#888',
              }}
            >
              {isPasswordVisible ? <FaEye /> : <FaEyeSlash />}
            </span>
          </div>
        </div>
        <button className="login-button" onClick={handleLogin}>
          LOGIN
        </button>
        <div className="login-signup-container">
          <a href="" onClick={handleForgotpassword} className="login-forgot-password-link">
            Forgot password?
          </a>
          <a href="" onClick={handleSignup} className="login-signup-link" >
            Sign Up
          </a>  
        </div>
        
      </div>
    </div>
  );
};

export default LoginCard;
